import React from "react"
import {useTranslation} from "react-i18next";
import * as impressumStyle from "./impressumStyle";


export const ImpressumComponent = () => {
    const {t} = useTranslation();
    return (
        <div style={impressumStyle.impressumContainer} className={'mb-2'}>
            Angaben gemäß § 5 TMG: <br/>
            Quant Edge Solutions GmbH <br/><br/>

            In den Hessengärten 24 <br/>

            61352 Bad Homburg <br/><br/>

            Vertreten durch: <br/>
            Thomas Zellerer <br/><br/>

            Kontakt: <br/>
            Telefon: +49 6172 923 3752 <br/>

            E-Mail: zellerer@quantqedgesolutions.com <br/><br/>

            Registereintrag: <br/>
            Eintragung im Handelsregister. <br/><br/>

            Registergericht: Bad Homburg v.d. Höhe <br/><br/>

            Registernummer: HRB 13142 <br/><br/>

            Umsatzsteuer-ID: <br/>
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: <br/>

            DE297911269 <br/><br/>

            Quelle: www.e-recht24.de <br/> <br/>

        </div>

    )
}